import { createElement } from "react";

const Heading = ({ children, h = 1, className = "", ...rest }) => {
  const styleByType =
    {
      1: "font-extrabold text-4xl mb-6 leading-[45px] sm:text-5xl sm:leading-[80px]",
      2: "font-extrabold xl:text-5xl lg:text-4xl leading-[80px] mb-6",
      3: "font-bold text-3xl sm:text-[40px] mb-6 sm:leading-[54px] sm:tracking-wides",
      4: "font-normal sm:text-2xl mb-6 sm:tracking-normal tracking-wider",
      5: "font-normal xl:text-2xl mb-6",
    }[h] || "";

  return createElement(
    `h${h}`,
    { className: [styleByType, className].join(" "), ...rest },
    children
  );
};

export default Heading;

import React from "react";

export const ScrollAnimation = ({
  children,
  animation = "slide-up",
  className = "",
  style = {},
  delay = 300,
  easing = "ease",
  duration = 500,
  ...rest
}) => {
  return (
    <div
      className={"flex items-center" + className}
      style={style}
      data-sal={animation}
      data-sal-delay={delay}
      data-sal-easing={easing}
      data-sal-duration={duration}
      {...rest}
    >
      {children}
    </div>
  );
};

export default ScrollAnimation;

import React from "react";

export const PageHeaderWrapper = ({ children, className, ...rest }) => {
  return (
    <section className={`${className} pt-[52px] sm:pt-[82px]`} {...rest}>
      {children}
    </section>
  );
};

export default PageHeaderWrapper;

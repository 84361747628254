import React from "react";
import './styles.css'

export const DotLine = ({ className, color = "#F71E5F", style = {}, ...rest }) => {
  return (
    <div 
      className="relative w-[16px] h-[16px] rounded-[50%]"
      style={{
        backgroundColor: `${color}`,
        ...style
    }}
    {...rest}
    >
      <div className="absolute w-[24px] h-[24px] opacity-50 rounded-[50%] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2" style={{backgroundColor: `${color}`}}/>
      <div className="absolute w-[40px] h-[40px] opacity-10 rounded-[50%] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2" style={{backgroundColor: `${color}`}}/>
    </div>
  );
};

export default DotLine;

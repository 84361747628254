import React from "react";

export const Circle = ({ className }) => {
  return (
    <div
      className={`rounded-[50%] ${className} `}
    />
  );
};

export default Circle;

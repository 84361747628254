import React, { useEffect, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '@components/Layout'
import PageHeaderWrapper from '@components/PageHeaderWrapper'
import { Heading } from '@components/Typography'
import { Button } from '@components/Button'
import { ScrollAnimation } from '@components/ScrollAnimation'
import { Circle } from "@components/decorator";
import { DotMap, DotLine } from "@components/decorator";

import ProductHeaderImage from '@assets/img/header/product.png'
import ProductBgImage from '@assets/img/product-bg-desktop.png'
import ImageOne from '@assets/img/product/product-1.png'
import ImageOneLayer from '@assets/img/product/product-1-layer.png'
import ImageOneDesktop from '@assets/img/product/product-1-deskop.png'
import ImageOneLayerDesktop from '@assets/img/product/product-1-layer-desktop.png'
import ImageTwo from '@assets/img/product/product-2.png'
import ImageThree from '@assets/img/product/product-3.png'
import ImageTwoLayer from '@assets/img/product/product-2-layer.png'
import ImageTwoDesktop from '@assets/img/product/product-2-desktop.png'
import ImageTwoLayerDesktop from '@assets/img/product/product-2-layer-desktop.png'
import ImageThreeDesktop from '@assets/img/product/product-3-desktop.png'
import BgProduct from '@assets/img/product/bg-product.png'

import { useTranslation } from 'react-i18next';

const ProductPage = () => {
  const data = useStaticQuery(query)
  const { t } = useTranslation()
  const pageTrans = 'product:'

  useEffect(() => {
    document.addEventListener('sal:in', scrollHandler)
    return () => document.removeEventListener('sal:in', scrollHandler)
  }, [])

  const scrollHandler = ({ detail }) => {
    if (detail.target.classList.value.includes('animate-image')) {
      setTimeout(() => {
        detail.target.querySelectorAll('.animate-image__item').forEach((elm) => {
          elm.classList.remove('hidden')
          elm.classList.add('animate__flipInY', 'animate__slow')
        })
      }, 100)
    }
  }
  const loginForm = 'https://app.imi.ai/'
  const registerLink = 'https://app.imi.ai/register'

  const openLink = (toppic) => {
    window.open(toppic)
  }

  return (
    <Layout seo={data.strapiHomepage.seo} navbarTheme="light">
      <PageHeaderWrapper
        className="bg-center bg-no-repeat relative lg:bg-cover"
        style={{ backgroundImage: `url(${ProductHeaderImage})` }}
      >
        <div className="bg-blue-800 bg-opacity-80 absolute top-0 left-0 right-0 bottom-0" />
        <div className="relative z-10 text-white flex justify-center items-center flex-col h-[342px] lg:h-[414px] lg:pb-[115px]">
          <Heading className="lg:mb-[4px] animate__animated animate__fadeInUp text-3xl pt-8" style={{ marginBottom: 10 }}>{t('menu.product')}</Heading>
          <p className="animate__animated animate__fadeInUp animate__delay-2s tracking-wider">{t('menu.home')}&nbsp;<span className="inline-block scale-[1.5] font-thin">/</span>&nbsp;{t('menu.product')}</p>
        </div>
      </PageHeaderWrapper>

      <section style={{ backgroundImage: `url(${ProductBgImage})`}} className="product-bg-image bg-no-repeat bg-contain pb-12 lg:bg-auto lg:pb-36 lg:mb-4 lg:bg-[221px,center] bg-[420px,200px]">
        <div className="">
          <div className="container">
            <ScrollAnimation delay={10} className="text-blue-800 text-center justify-center">
              <h2 className="lg:text-3xl lg:font-extrabold lg:mt-18 lg:pt-6 text-2xl font-bold mt-12 text-[#0E3389]">{t(`${pageTrans}heading`)}</h2>
            </ScrollAnimation>
          </div>

          <div className="container">
            <div className="relative mt-16 lg:grid lg:grid-cols-12 lg:items-center">
              <div className="lg:col-start-2 lg:col-end-6 lg:text-6xl">
                <div className="absolute z-[-1] h-[281px] w-[281px] top-0 left-[-66px] lg:hidden">
                  <Circle className="h-[281px] w-[281px] bg-orange-100"/>
                </div>
                <div className="lg:text-right lg:relative flex lg:justify-end md:justify-center">
                  <div className="absolute z-[-1] xl:right-[-69px] xl:top-[-144px] lg:right-[-57px] lg:top-[-87px] lg:block hidden">
                    <span className="text-xl text-navy-100 font-bold lg:text-7xxl">1</span>
                  </div>
                  <div className="lg:pr-3 lg:mt-[-50px] xl:mt-[-117px]">
                    <div className="lg:absolute xl:right-[-52px] lg:right-[-42px] md:right-[-45px] xl:top-[-6px] lg:top-[46px] lg:block hidden">
                      <DotLine/>
                    </div>
                    <ScrollAnimation delay={10} className=" lg:justify-end md:justify-center">
                      <h2 className="lg:text-3xl lg:font-extrabold text-2xl font-bold text-blue-700">{t(`${pageTrans}section1.heading`)}</h2>
                    </ScrollAnimation>

                    <ScrollAnimation delay={10} className=" lg:justify-end md:justify-center">
                      <p className="mt-4 text-base lg:text-lg lg:mt-[16px]">{t(`${pageTrans}section1.content1`)}</p>
                    </ScrollAnimation>

                    <ScrollAnimation delay={10} className=" lg:justify-end md:justify-center">
                      <Button size="sm" className="lg:py-0 lg:px-0 py-0 px-0 lg:mt-[16px] bg-blue mt-5 w-[167px] h-[44px] text-base"
                              onClick={() => openLink(registerLink)}
                      >{t(`${pageTrans}section1.cta1`)}</Button>
                    </ScrollAnimation>

                  </div>
                </div>
              </div>

              <div className="lg:col-start-8 lg:col-end-12">
                <div className="flex justify-center lg:justify-start lg:pl-[12px] xl:pt-[30px] lg:pt-0">
                  <div className="relative w-[342px] h-[364px] mt-[25px] lg:mt-0 xl:mt-[25px] xl:w-[454px] xl:h-[484px]">
                    <ScrollAnimation animation="fade" delay={10} duration={10} className="mt-3 animate-image xl:hidden block">
                      <div className="animate-image__item animate__animated absolute top-0 left-0 animate__delay-1s z-10 w-[342px] h-[364px] ">
                        <img src={ImageOne} className="w-full h-full"/>
                      </div>
                    </ScrollAnimation>
                    <ScrollAnimation animation="fade" delay={10} duration={10} className="mt-3 animate-image xl:block hidden">
                      <div className="animate-image__item animate__animated absolute top-0 left-0 animate__delay-1s z-10 w-[454px] h-[484px]">
                        <img src={ImageOneDesktop} className="w-full h-full"/>
                      </div>
                    </ScrollAnimation>
                    <div className="animate-image__item animate__animated absolute top-0 left-0 w-[342px] h-[354px] z-1 xl:hidden block">
                      <img src={ImageOneLayer} className="w-full h-full"/>
                    </div>
                    <div className="animate-image__item animate__animated absolute top-0 left-0 w-[463px] h-[465px] z-1  xl:block hidden">
                      <img src={ImageOneLayerDesktop} className="w-full h-full"/>
                    </div>
                    <div className="absolute z-[-1] h-[205px] w-[205px] bottom-[-85px] left-[-115px] xl:h-[281px] xl:w-[281px] xl:block hidden">
                      <ScrollAnimation delay={100} duration={200} animation="zoom-in">
                        <Circle className="animate__delay-2s h-[205px] w-[205px]  xl:h-[281px] xl:w-[281px] bg-orange-100"/>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>

                <div className="absolute z-[-1] h-[205px] w-[205px] bottom-0 right-[-97px] lg:hidden block">
                  <ScrollAnimation delay={100} duration={200} animation="zoom-in">
                    <Circle className="animate__delay-2s h-[205px] w-[205px] bg-orange-100"/>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="relative lg:grid lg:grid-cols-12 lg:items-center lg:mt-28 ">
              <div className="lg:col-start-8 lg:col-end-13 lg:text-6xl lg:order-last lg:relative">
                <div className="lg:absolute xl:left-[-56px] lg:left-[-70px] xl:top-[28px] lg:top-[75px] lg:block hidden">
                  <DotLine/>
                </div>
                <DotMap className="bottom-[-215px] left-[101px] lg:block hidden" style={{ height: 197, width: 168, zIndex: 1 }}/>  
                <div className="lg:pl-12 md">
                  <ScrollAnimation className=" lg:mt-0 mt-[66px] xl:mt-[-6px] lg:justify-start md:justify-center">
                    <h2 className="lg:text-3xl text-2xl font-bold text-blue-700">{t(`${pageTrans}section2.heading`)}</h2>
                  </ScrollAnimation>

                  <ScrollAnimation className="lg:mt-6 mt-4 text-xl lg:tracking-[-1px] lg:justify-end md:justify-center">
                    <p className="md:text-center lg:text-left">
                      {t(`${pageTrans}section2.content1`)}
                    </p>
                  </ScrollAnimation>
                </div>
              </div>

              <div className="lg:col-span-5 lg:text-6xl">
                <div className="flex justify-center">
                  <div className="relative h-[364px] w-[342px] xl:h-[484px] xl:w-[463px] mt-5  xl:hidden block">
                    <ScrollAnimation animation="fade" delay={10} duration={10} className="animate-image">
                      <div className="animate__animated animate-image__item absolute top-[-9px] left-0 animate__delay-2s w-[342px] h-[364px] z-10">
                        <img src={ImageTwo} className="w-full h-full"/>
                      </div>
                    </ScrollAnimation>
                    <div className="absolute top-0 left-0 w-[343px] h-[344px] z-1">
                      <img src={ImageTwoLayer} className="w-full h-full"/>
                    </div>
                  </div>
                  <div className="relative  xl:w-[463px] xl:h-[484px] lg:h-[364px] lg:w-[342px] hidden lg:block">
                    <div className="absolute z-[-1] right-[-112px] xl:right-[-190px] top-[84px] xl:top-[71px] lg:top-[45px]">
                      <span className="text-xl text-navy-100 font-bold lg:text-7xxl">2</span>
                    </div>
                    <DotMap className="top-[-89px] left-[-75px] hidden lg:block" style={{ height: 111, width: 111, zIndex: 1 }} color="#2263CB"/>  
                    <ScrollAnimation animation="fade" delay={10} duration={10} className="animate-image hidden xl:block">
                      <div className="animate__animated animate-image__item absolute top-[-9px] left-0 animate__delay-2s w-[463px] h-[484px] z-10">
                        <img src={ImageTwoDesktop} className="w-full h-full"/>
                      </div>
                    </ScrollAnimation>
                    <div className="absolute top-0 left-0 w-[463px] h-[465px] z-1 hidden xl:block">
                      <img src={ImageTwoLayerDesktop} className="w-full h-full"/>
                    </div>
                  </div>
                </div>
                <DotMap className="bottom-0 right-[-40px] lg:hidden block" style={{ height: 160, width: 140, zIndex: 1 }} color="#2263CB"/>  
              </div>
            </div>
          </div>
          
          <div className="lg:relative">
            <div className="lg:block hidden absolute z-0 right-0 top-[-19px]">
              <img src={BgProduct} alt="" />
            </div>
            <div className="container">
              <div className="relative lg:mt-36 lg:pt-2 mt-16 lg:grid lg:grid-cols-12 lg:items-center">
                <div className="lg:col-start-1 lg:col-end-6 lg:text-6xl lg:relative">
                  <div className="absolute z-[-1] xl:right-[-78px] lg:right-[-96px] xl:top-[-87px] lg:top-[-90px] lg:block hidden">
                    <span className="text-xl text-navy-100 font-bold lg:text-7xxl">3</span>
                  </div>
                  <div className="lg:absolute lg:right-[-75px] xl:right-[-53px] xl:top-[46px] lg:top-[56px] lg:block hidden">
                    <DotLine/>
                  </div>
                  <div className="mt-12 lg:mt-[-45px] lg:text-right lg:pr-12 lg:pl-4">
                    <ScrollAnimation className=" lg:justify-end md:justify-center">
                      <h2 className="lg:text-3xl lg:font-extrabold text-2xl font-bold text-blue-700">{t(`${pageTrans}section3.heading`)}</h2>
                    </ScrollAnimation>

                    <ScrollAnimation className=" lg:mt-6 mt-5 text-xl lg:tracking-[-1px]">
                      <p className="md:text-center lg:text-right">
                        {t(`${pageTrans}section3.content1`)}
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>

                <div className="lg:col-start-8 lg:col-end-13 ">
                  <div className="flex justify-center">
                    <div className="relative h-[364px] w-[342px] mt-5 xl:hidden block">
                      <ScrollAnimation animation="fade" delay={10} duration={10} className="animate-image">
                        <div className="animate__animated animate-image__item absolute top-0 left-0 animate__delay-2s w-[343px] h-[364px] z-10 hidden">
                          <img src={ImageThree} className="w-full h-full"/>
                        </div>
                      </ScrollAnimation>
                      <div className="animate__animated animate-image__item absolute top-0 left-0 w-[343px] h-[343px] z-1">
                        <img src={ImageOneLayer} className="w-full h-full"/>
                      </div>
                    </div>
                    <div className="relative xl:w-[454px] xl:h-[484px] mt-5 xl:block hidden">
                      <ScrollAnimation animation="fade" delay={10} duration={10} className="animate-image">
                        <div className="animate__animated animate-image__item absolute top-0 left-0 animate__delay-2s w-[454px] h-[484px] z-10 hidden">
                          <img src={ImageThreeDesktop} className="w-full h-full"/>
                        </div>
                      </ScrollAnimation>
                      <div className="animate__animated animate-image__item absolute top-0 left-0 w-[463px] h-[465px] z-1">
                        <img src={ImageOneLayerDesktop} className="w-full h-full"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

const query = graphql`
  query {
    strapiHomepage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

export default ProductPage

import React from "react";

export const DotMap = ({ className, color = "#d3fae5", style = {}, ...rest }) => {
  return (
    <span
      className={`dot-map w-[160px] h-[220px] absolute scale-[0.70] sm:scale-100 z-[-1] ${className}`}
      style={{
        backgroundImage: `radial-gradient(${color} 20%, transparent 20%)`,
        backgroundPosition: "0 0",
        backgroundSize: "28px 28px",
        ...style
      }}
      {...rest}
    />
  );
};

export default DotMap;
